$(document).ready(function(){
  const tabs = $("#tabs>div");
  const tabNavigation = $('#tabs-navigation li a');
  tabs.not(':first').hide();
  tabNavigation.on('click',function(){
    let self = $(this);
    let tabId = self.data('tab');
    tabNavigation.each(function(){
      $(this).removeClass('active');
    });
    self.addClass('active');
    tabs.each(function(){
      $(this).hide();
    });
    $(tabId).show();
    return false;
  });
});